import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import type { ContractAddresses, ContractsAbi } from '~/types/contractAddresses';

export enum Tokens {
  pearl = 'pearl',
  gold = 'gold',
  goldOre = 'goldOre',
  coal = 'coal',
  iron = 'iron',
  ironOre = 'ironOre',
  palladium = 'palladium',
  platina = 'platina',
  platinaOre = 'platinaOre',
  rhodium = 'rhodium',
  silver = 'silver',
  silverOre = 'silverOre',
  wood = 'wood',
  hammer = 'hammer',
  nails = 'nails',
  woodenPlank = 'woodenPlank',
  blackPearl = 'blackPearl',
  testNft = 'testNft',

  earrings = 'earrings',
  necklaces = 'necklaces',
  rings = 'rings',
  ships = 'ships',
  palms = 'palms',
  chairs = 'chairs',

  seasonPoints = 'seasonPoints'
}

export enum TokenUniquenessType {
  Common = 'common',
  Uncommon = 'uncommon',
  Rare = 'rare'
}

export type MiningsTableRow = {
  amount: BigNumber;
  createdAt: string;
  available: string;
};

export type ExtraReward = {
  label: string;
  media: string;
};

export type TTokensConfigItem = {
  name: Record<string, keyof ContractAddresses | keyof ContractsAbi>;
  media: string;
  label: string;
  methodName: string;
  extraReward?: ExtraReward;
  rate?: number;
  interface: 'erc20' | 'erc721';
};

export type TTokensConfig = Record<Tokens | 'usdt', TTokensConfigItem>;

export const tokensConfig: TTokensConfig = {
  usdt: {
    name: {
      contract: 'usdt'
    },
    methodName: 'USDT',
    label: 'usdt',
    media: '',
    interface: 'erc20'
  },
  [Tokens.pearl]: {
    name: {
      contract: Tokens.pearl,
      store: 'pearlStore',
      yield: 'pearlYield'
    },
    methodName: 'Pearl',
    label: 'corePearl',
    media: '/img/tokens/pearl.png',
    interface: 'erc20'
  },
  [Tokens.gold]: {
    name: {
      contract: 'gold',
      store: 'goldStore',
      yield: 'goldYield'
    },
    label: 'coreGold',
    methodName: 'Gold',
    media: '/img/tokens/gold.png',
    interface: 'erc20'
  },
  [Tokens.goldOre]: {
    name: {
      contract: 'goldOre',
      store: 'goldOreStore',
      yield: 'goldOreYield'
    },
    rate: 0.5,
    label: 'coreGoldOre',
    methodName: 'Gold Ore',
    media: '/img/tokens/goldOre.png',
    extraReward: {
      label: 'Shining metallite',
      media: '/img/tokens/shining_metallite.png'
    },
    interface: 'erc20'
  },
  [Tokens.coal]: {
    name: {
      contract: 'coal',
      store: 'coalStore',
      yield: 'coalYield'
    },
    rate: 0.001,
    label: 'coreCoal',
    methodName: 'Coal',
    media: '/img/tokens/coal.png',
    extraReward: {
      label: 'Ctystalline Ore',
      media: '/img/tokens/ctystalline_ore.png'
    },
    interface: 'erc20'
  },
  [Tokens.iron]: {
    name: {
      contract: 'iron',
      store: 'ironStore',
      yield: 'ironYield'
    },
    label: 'coreIron',
    methodName: 'Iron',
    media: '/img/tokens/iron.png',
    interface: 'erc20'
  },
  [Tokens.ironOre]: {
    name: {
      contract: 'ironOre',
      store: 'ironOreStore',
      yield: 'ironOreYield'
    },
    rate: 0.015,
    extraReward: {
      label: 'Shining metallite',
      media: '/img/tokens/shining_metallite.png'
    },
    label: 'coreIronOre',
    methodName: 'Iron Ore',
    media: '/img/tokens/ironOre.png',
    interface: 'erc20'
  },
  [Tokens.palladium]: {
    name: {
      contract: 'palladium',
      store: 'palladiumStore',
      yield: 'palladiumYield'
    },
    label: 'corePalladium',
    methodName: 'Palladium',
    media: '/img/tokens/palladium.png',
    interface: 'erc20'
  },
  [Tokens.platina]: {
    name: {
      contract: 'platina',
      store: 'platinaStore',
      yield: 'platinaYield'
    },
    label: 'corePlatina',
    methodName: 'Platina',
    media: '/img/tokens/platina.png',
    interface: 'erc20'
  },
  [Tokens.platinaOre]: {
    name: {
      contract: 'platinaOre',
      store: 'platinaOreStore',
      yield: 'platinaOreYield'
    },
    label: 'corePlatinaOre',
    methodName: 'Platina Ore',
    media: '/img/tokens/platinaOre.png',
    interface: 'erc20'
  },
  [Tokens.rhodium]: {
    name: {
      contract: 'rhodium',
      store: 'rhodiumStore',
      yield: 'rhodiumYield'
    },
    label: 'coreRhodium',
    methodName: 'Rhodium',
    media: '/img/tokens/rhodium.png',
    interface: 'erc20'
  },
  [Tokens.silver]: {
    name: {
      contract: 'silver',
      store: 'silverStore',
      yield: 'silverYield'
    },
    label: 'coreSilver',
    methodName: 'Silver',
    media: '/img/tokens/silver.png',
    interface: 'erc20'
  },
  [Tokens.silverOre]: {
    name: {
      contract: 'silverOre',
      store: 'silverOreStore',
      yield: 'silverOreYield'
    },
    label: 'coreSilverOre',
    methodName: 'Silver Ore',
    media: '/img/tokens/silverOre.png',
    interface: 'erc20'
  },
  [Tokens.wood]: {
    name: {
      contract: 'wood',
      store: 'woodStore',
      yield: 'woodYield'
    },
    extraReward: {
      label: 'Acorns',
      media: '/img/tokens/acorns.png'
    },

    rate: 0.005,
    label: 'coreWood',
    methodName: 'Wood',
    media: '/img/tokens/wood.png',
    interface: 'erc20'
  },
  [Tokens.woodenPlank]: {
    name: {
      contract: 'woodenPlank',
      store: 'woodenPlankStore',
      yield: 'woodenPlankYield'
    },
    label: 'coreWoodenPlank',
    methodName: 'Wooden Plank',
    media: '/img/tokens/woodenPlank.png',
    interface: 'erc20'
  },
  [Tokens.hammer]: {
    name: {
      contract: 'hammer',
      store: 'hammerStore',
      yield: 'hammerYield'
    },
    label: 'coreSimpleHammer',
    methodName: 'Hammer',
    media: '',
    interface: 'erc20'
  },
  [Tokens.nails]: {
    name: {
      contract: 'nails',
      store: 'nailsStore',
      yield: 'nailsYield'
    },
    label: 'coreNails',
    methodName: 'Nails',
    media: '',
    interface: 'erc20'
  },
  [Tokens.blackPearl]: {
    name: {
      contract: 'blackPearl'
    },
    label: 'coreBlackPearl',
    methodName: 'Black Pearl',
    media: '/img/icons/blackPearl.svg',
    interface: 'erc20'
  },
  [Tokens.testNft]: {
    name: {
      contract: 'testNft'
    },
    label: 'Test Nft',
    methodName: 'Test Nft',
    media: '/img/tokens/test-nft.png',
    interface: 'erc721'
  },
  [Tokens.seasonPoints]: {
    name: {
      contract: 'seasonPoints'
    },
    label: 'seasonPoints',
    methodName: 'Season Points',
    media: tokens.seasonPoints,
    interface: 'erc20'
  },
  [Tokens.earrings]: {
    name: {
      contract: 'earrings'
    },
    label: 'coreEarrings',
    methodName: 'Earrings',
    media: '',
    interface: 'erc721'
  },
  [Tokens.necklaces]: {
    name: {
      contract: 'necklaces'
    },
    label: 'coreNecklaces',
    methodName: 'Necklace',
    media: '',
    interface: 'erc721'
  },
  [Tokens.rings]: {
    name: {
      contract: 'rings'
    },
    label: 'coreRings',
    methodName: 'Ring',
    media: '',
    interface: 'erc721'
  },
  [Tokens.ships]: {
    name: {
      contract: 'ships'
    },
    label: 'nftChristmasShip',
    methodName: 'Ships',
    media: '',
    interface: 'erc721'
  },
  [Tokens.palms]: {
    name: {
      contract: 'palms'
    },
    label: 'nftChristmasPalm',
    methodName: 'Palms',
    media: '',
    interface: 'erc721'
  },

  [Tokens.chairs]: {
    name: {
      contract: 'chairs'
    },
    label: 'nftChair',
    methodName: 'Chair',
    media: '',
    interface: 'erc721'
  }
};
