import pearl from './pearl.png';
import coal from './coal.png';
import gold from './gold.png';
import goldOre from './goldOre.png';
import iron from './iron.png';
import ironOre from './ironOre.png';
import palladium from './palladium.png';
import platina from './platina.png';
import platinaOre from './platinaOre.png';
import rhodium from './rhodium.png';
import silver from './silver.png';
import silverOre from './silverOre.png';
import wood from './wood.png';
import nails from './nails.png';
import hammer from './hammer.png';
import woodenPlank from './woodenPlank.png';
import seasonPoints from './seasonPoints.png';
import earrings from './earrings.png';
import necklaces from './necklaces.png';
import rings from './rings.png';
import palmCommon from './palmCommon.png';
import palmUncommon from './palmUncommon.png';
import palmRare from './palmRare.png';
import chairCommon from './chairCommon.png';
import chairUncommon from './chairUncommon.png';
import chairRare from './chairRare.png';

const ship1 = 'https://static.pearldivergame.com/staging/output/ships-00.gif';
const ship2 = 'https://static.pearldivergame.com/staging/output/ships-01.gif';
const ship3 = 'https://static.pearldivergame.com/staging/output/ships-02.gif';
const ship4 = 'https://static.pearldivergame.com/staging/output/ships-03.gif';
const ship5 = 'https://static.pearldivergame.com/staging/output/ships-04.gif';
const ship6 = 'https://static.pearldivergame.com/staging/output/ships-05.gif';
const ship7 = 'https://static.pearldivergame.com/staging/output/ships-06.gif';
import ship8 from './ship8.png';
import ship9 from './ship9.png';
import ship10 from './ship10.png';
import usdt from './usdt.png';
import blackPearl from './blackPearl.png';
import testNft from './testNft.png';

import { type Tokens, TokenUniquenessType } from '~/utils/constants';

enum ExtraTokens {
  usdt = 'usdt'
}

enum TokensUniqueness {
  chairs = 'chairs',
  palms = 'palms'
}

enum TokensShips {
  ships = 'ships'
}

export type TokenIconUniquenessType = Record<TokenUniquenessType, string>;

type TTokenIconValue<K> = K extends Tokens | ExtraTokens
  ? string
  : K extends TokensShips
    ? string[]
    : TokenIconUniquenessType;

export type TokenIconType = {
  [K in Tokens | ExtraTokens | TokensUniqueness | TokensShips]: TTokenIconValue<K>;
};

const tokens: TokenIconType = {
  pearl,
  coal,
  gold,
  goldOre,
  iron,
  ironOre,
  palladium,
  platina,
  platinaOre,
  rhodium,
  silver,
  silverOre,
  wood,
  seasonPoints,
  blackPearl,
  testNft,
  earrings,
  rings,
  necklaces,
  usdt,
  nails,
  hammer,
  woodenPlank,
  ships: [ship1, ship2, ship3, ship4, ship5, ship6, ship7, ship8, ship9, ship10],
  palms: {
    common: palmCommon,
    uncommon: palmUncommon,
    rare: palmRare
  },
  chairs: {
    common: chairCommon,
    uncommon: chairUncommon,
    rare: chairRare
  }
};

export default tokens;
