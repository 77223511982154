import { SeasonModalView } from '~/types/season';

export const SEASON_SWITCHER_MODAL_OPTIONS = [
  {
    name: SeasonModalView.burnTokens,
    iconSrc: '/img/burnTokens.png'
  },
  {
    name: SeasonModalView.leaderboard,
    iconSrc: '/img/season_cup.svg'
  }
];

export const SEASON_MODAL_VIEW_CONFIG = {
  [SeasonModalView.burnTokens]: {
    descriptionKey: 'seasonModalDescriptionBurn',
    contentTitleKey: 'seasonModalContentTitleBurn'
  },
  [SeasonModalView.leaderboard]: {
    descriptionKey: null,
    contentTitleKey: 'seasonModalContentTitleLeaderboard'
  },
  [SeasonModalView.end]: {
    descriptionKey: 'seasonModalDescriptionEnd',
    contentTitleKey: null
  },
  [SeasonModalView.rewards]: {
    descriptionKey: 'seasonModalDescriptionEnd',
    contentTitleKey: null
  },
  [SeasonModalView.start]: {
    descriptionKey: 'seasonModalDescriptionBurn',
    contentTitleKey: 'seasonModalContentTitleStart'
  }
};
