export type TSeasonConfig = {
  seasonYear: number;
  startDate: string;
  endDate: string;
  config: TSeasonConfigItem[];
};

export type TSeasonConfigItem = {
  pointsMultiplier: number;
  tokenAddress: string;
};

export enum SeasonModalView {
  burnTokens = 'burnTokens',
  leaderboard = 'leaderboard',
  start = 'start',
  end = 'end',
  rewards = 'rewards'
}

export interface Reward {
  tokenAddress: string;
  amount: number;
  isNft: boolean;
}
