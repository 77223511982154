import { tokensConfig, type TTokensConfigItem } from './tokens';

export interface PackItem {
  price: number;
  tokenReward: number;
  extraRewards: number;
  name: string;
  imagePath: string;
  token: TTokensConfigItem;
  key: string;
  descriptionKey?: string;
  extraRewardKey?: string;
}

export const PEARL_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 1000,
    extraRewards: 0,
    name: 'Mini handful of Pearls',
    imagePath: 'pearl-1000',
    token: tokensConfig.pearl,
    key: 'packMini'
  },
  {
    price: 50,
    tokenReward: 5000,
    extraRewards: 0,
    name: 'Standard handful of Pearls',
    imagePath: 'pearl-5000',
    token: tokensConfig.pearl,
    key: 'packStandard'
  },
  {
    price: 100,
    tokenReward: 10000,
    extraRewards: 0,
    name: 'Handful of Pearls',
    imagePath: 'pearl-10000',
    token: tokensConfig.pearl,
    key: 'packHandful'
  },
  {
    price: 250,
    tokenReward: 25000,
    extraRewards: 0,
    name: 'Extra handful of Pearls',
    imagePath: 'pearl-25000',
    token: tokensConfig.pearl,
    key: 'packExtraHandful'
  },
  {
    price: 500,
    tokenReward: 50000,
    extraRewards: 0,
    name: 'Shell Full of Pearls',
    imagePath: 'pearl-50000',
    token: tokensConfig.pearl,
    key: 'packShell'
  },
  {
    price: 1000,
    tokenReward: 100000,
    extraRewards: 500,
    extraRewardKey: 'Black Pearl',
    name: 'Pouch Full of Pearls',
    imagePath: 'pearl-100000',
    token: tokensConfig.pearl,
    key: 'packPouch'
  },
  {
    price: 2500,
    tokenReward: 250000,
    extraRewards: 1625,
    extraRewardKey: 'Black Pearl',
    name: 'Bag Full of Pearls',
    imagePath: 'pearl-250000',
    token: tokensConfig.pearl,
    key: 'packBag'
  },
  {
    price: 5000,
    tokenReward: 500000,
    extraRewards: 3500,
    extraRewardKey: 'Black Pearl',
    name: 'Extra bag Full of Pearls',
    imagePath: 'pearl-500000',
    token: tokensConfig.pearl,
    key: 'packExtraBag'
  },
  {
    price: 10000,
    tokenReward: 1000000,
    extraRewards: 8500,
    extraRewardKey: 'Black Pearl',
    name: 'Small Plate of Pearls',
    imagePath: 'pearl-1000000',
    token: tokensConfig.pearl,
    key: 'packSmallPlate'
  },
  {
    price: 15000,
    tokenReward: 1500000,
    extraRewards: 14000,
    extraRewardKey: 'Black Pearl',
    name: 'Plate Full of Pearls',
    imagePath: 'pearl-1500000',
    token: tokensConfig.pearl,
    key: 'packPlateFull'
  },
  {
    price: 20000,
    tokenReward: 2000000,
    extraRewards: 19500,
    extraRewardKey: 'Black Pearl',
    name: 'Big Plate Full of Pearls',
    imagePath: 'pearl-2000000',
    token: tokensConfig.pearl,
    key: 'packBigPlateFull'
  },
  {
    price: 25000,
    tokenReward: 2500000,
    extraRewards: 25000,
    extraRewardKey: 'Black Pearl',
    name: 'Tank Full of Pearls',
    imagePath: 'pearl-2500000',
    token: tokensConfig.pearl,
    key: 'packFullOfPearls'
  }
];

export const COAL_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 10000,
    extraRewards: 0,
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'miniHandfulOfCoal'
  },
  {
    price: 100,
    tokenReward: 100000,
    extraRewards: 0,
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'standardHandfulOfCoal'
  },
  {
    price: 500,
    tokenReward: 500000,
    extraRewards: 0,
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'handfulOfCoal'
  },
  {
    price: 1000,
    tokenReward: 1000000,
    extraRewards: 720,
    extraRewardKey: 'Crystalline Ore',
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'smallPouchOfCoal'
  },
  {
    price: 2500,
    tokenReward: 2500000,
    extraRewards: 2600,
    extraRewardKey: 'Crystalline Ore',
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'pouchFullOfCoal'
  },
  {
    price: 10000,
    tokenReward: 10000000,
    extraRewards: 14400,
    extraRewardKey: 'Crystalline Ore',
    name: 'Coal mining License x2',
    descriptionKey: 'packCoalDescription',
    imagePath: 'coal-license',
    token: tokensConfig.coal,
    key: 'crateOfCoal'
  }
];

export const GOLD_ORE_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 20,
    extraRewards: 0,
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'miniHandfulOfGoldOre'
  },
  {
    price: 100,
    tokenReward: 200,
    extraRewards: 0,
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'standardHandfulOfGoldOre'
  },
  {
    price: 500,
    tokenReward: 1000,
    extraRewards: 0,
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'HandfulOfGoldOre'
  },
  {
    price: 1000,
    tokenReward: 2000,
    extraRewards: 1100,
    extraRewardKey: 'Shining Metallite',
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'smallPouchOfGoldOre'
  },
  {
    price: 2500,
    tokenReward: 5000,
    extraRewards: 4000,
    extraRewardKey: 'Shining Metallite',
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'pouchOfGoldOre'
  },
  {
    price: 10000,
    tokenReward: 20000,
    extraRewards: 22500,
    extraRewardKey: 'Shining Metallite',
    name: 'Gold mining license x2',
    descriptionKey: 'packGoldDescription',
    imagePath: 'gold-ore-license',
    token: tokensConfig.goldOre,
    key: 'crateOfGoldOre'
  }
];

export const IRON_ORE_PACK_DATA: PackItem[] = [
  {
    price: 12,
    tokenReward: 800,
    extraRewards: 0,
    name: 'Mini handful of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'miniHandfulOfIronOre'
  },
  {
    price: 120,
    tokenReward: 8000,
    extraRewards: 0,
    name: 'Standard Handful of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'standardHandfulOfIronOre'
  },
  {
    price: 600,
    tokenReward: 40000,
    extraRewards: 0,
    name: 'Handful of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'handfulOfIronOre'
  },
  {
    price: 1200,
    tokenReward: 80000,
    extraRewards: 1200,
    extraRewardKey: 'Shining Metallite',
    name: 'Pouch of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'smallPouchOfIronOre'
  },
  {
    price: 2700,
    tokenReward: 180000,
    extraRewards: 3600,
    extraRewardKey: 'Shining Metallite',
    name: 'Crate of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'pouchOfIronOre'
  },
  {
    price: 12000,
    tokenReward: 800000,
    extraRewards: 18750,
    extraRewardKey: 'Shining Metallite',
    name: 'Big crate of Iron Ore',
    descriptionKey: 'packIronOreDescription',
    imagePath: 'iron-ore-license',
    token: tokensConfig.ironOre,
    key: 'crateOfIronOre'
  }
];

export const WOOD_PACK_DATA: PackItem[] = [
  {
    price: 10,
    tokenReward: 2000,
    extraRewards: 0,
    name: 'Small bundle of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'miniHandfulOfIronOre'
  },
  {
    price: 100,
    tokenReward: 20000,
    extraRewards: 0,
    name: 'Bundle of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'standardHandfulOfIronOre'
  },
  {
    price: 500,
    tokenReward: 100000,
    extraRewards: 0,
    name: 'Big bundle of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'handfulOfIronOre'
  },
  {
    price: 1000,
    tokenReward: 200000,
    extraRewards: 30000,
    extraRewardKey: 'Acorns',
    name: 'Small crate of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'smallPouchOfIronOre'
  },
  {
    price: 2500,
    tokenReward: 500000,
    extraRewards: 175000,
    extraRewardKey: 'Acorns',
    name: 'Crate of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'pouchOfIronOre'
  },
  {
    price: 10000,
    tokenReward: 2000000,
    extraRewards: 1300000,
    extraRewardKey: 'Acorns',
    name: 'Big crate of Wood',
    descriptionKey: 'packWoodDescription',
    imagePath: 'wood-license',
    token: tokensConfig.wood,
    key: 'crateOfIronOre'
  }
];
